<template>
  <div class="signupInfo">
    <h2>报考条件</h2>
    <div class="infoContent">
      <div class="content">
        <p>（一）报名条件</p>
        <p>1.符合下列条件的中国公民可以报名：</p>
        <p>&emsp;（1）遵守中华人民共和国宪法和法律。</p>
        <p>
          &emsp;（2）国家承认学历的各类高、中等学校在校生以外的在职从业人员和社会其他人员。
        </p>
        <p>
          &emsp;（3）报考高起本或高起专考生应高级中等教育学校毕业或者具有同等学力。高级中等教育学校在校学生包括应届毕业班学生不得报考。报考专升本的考生必须是已取得经教育部审定核准的国民教育系列高等学校、高等教育自学考试机构颁发的专科毕业证书、本科结业证书或以上证书的人员。经广东省招生委员会核准录取的成人高等学校应届专科毕业生（指2022年3月31日前可取得专科毕业证书的学生）可报考我省成人高等学校（不含外省高校在我省设立的函授站），录取考生2022年春季入学报到时须持有经教育部审定核准的国民教育系列高等学校、高等教育考试机构颁发的专科或以上毕业证书，否则不得办理注册手续，同时取消专升本录取资格。
        </p>
        <p>&emsp;（4）身体健康，生活能自理，不影响所报专业学习。</p>
        <p>
          &emsp;（5）根据卫生部《关于2004年全国成人高校医学类专业招生条件建议的函》（卫科教便函〔2004〕57号）要求，报考成人高校医学门类专业的考生应具备以下条件：
        </p>
        <p>
          &emsp;&emsp;①报考临床医学、口腔医学、预防医学、中医学等临床类专业的人员，应当取得省级卫生健康行政部门颁发的相应类别的执业助理医师及以上资格证书或取得国家认可的普通中专及以上相应专业学历；或者县级以上卫生健康行政部门颁发的乡村医生执业证书并具有中专学历或中专水平证书。
        </p>
        <p>
          &emsp;&emsp;②报考护理学专业的人员应当取得省级卫生健康行政部门颁发的执业护士证书。
        </p>
        <p>
          &emsp;&emsp;③报考医学门类其它专业的人员应是从事卫生、医药行业工作的在职专业技术人员。
        </p>
        <p>&emsp;&emsp;④考生报考的专业原则上应与所从事的专业对口。</p>
        <p>
          &emsp;&emsp;对不符合上述条件且要求报考医学门类的考生，各市、县（市、区）招生办公室要以适当方式告知其毕业后所获得的医学成人高等教育文凭将不能作为参加执业医师、执业护士考试的依据，请其慎重报考。
        </p>
        <p>
          2.在中国定居并符合上述报名条件的港澳居民持《港澳居民来往内地通行证》或《港澳居民居住证》、台湾居民持《台湾居民来往大陆通行证》或《台湾居民居住证》、外国侨民持《外国人永久居留身份证》，可在有关省级招生考试机构指定的地点报名
        </p>
        <br />
        <p>
          （二）报名采用网上报名方式，网上报名审核未通过的、申请照顾加分（除贫困山区县以及25周岁以上照顾加分外）和免试入学等类型的考生还须进行现场审核。网上报名和现场审核时间为9月11日—17日。报名流程和具体工作安排另文通知。
        </p>
        <br />
        <p>
          （三）所有参加全国统考、院校单考和符合照顾录取免试入学的考生均需在规定时间办理报名手续。普通高职（专科）毕业生服兵役退役、“下基层”服务期满的成人本科教育免试生的报名时间，与成人高考报名其他考生相同，报名时间及方式见附2。
        </p>
        <br />
        <p>
          （四）报考各类成人高校的考生，报名时需建立考生电子档案。考生电子档案按照教育部颁发的《各类成人高等学校招生工作管理信息标准》制作。考生电子档案内容包括考生的报名信息、志愿信息、身份证信息以及成人高考成绩等。考生电子档案一经确认，任何人不得擅自更改。考生应认真核对本人的报名表，并对其真实性和准确性负责。考试与录取管理以考生确认的报名表为依据。因考生本人填报的信息错误以及填报志愿不当原因而落选的，其责任应由考生本人负责。
        </p>
        <br />
        <p>
          （五）根据共青团广东省委员会等5部门《关于印发&lt;广东省2021年新生代产业工人“圆梦计划”工作实施方案>的通知》精神，报考“圆梦计划”的新生代产业工人应按要求登陆“圆梦计划”官网（http://www.yuanmengjihua.com）或关注“广东青年之声”微信公众号报名，并在省招生办规定时间内进行成人高考网上报名和缴费。成人高校招生录取结束后，“圆梦计划”报考资格、圆梦学员资助名单的确认工作按团省委规定的程序办理。
        </p>
      </div>
      <div class="form">
        <div class="title">
          <p>省委省政府确定的原扶贫开发</p>
          <p>重点县和山区县名单</p>
        </div>
        <div class="table">
          <table>
            <thead>
              <tr>
                <td>地市</td>
                <td>县（市、区）</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>广州市</td>
                <td>从化区</td>
              </tr>
              <tr>
                <td>韶关市</td>
                <td>
                  曲江县、始兴县、仁化县、翁源县、乳源瑶族自治县★、新丰县★、乐昌市★、南雄市★
                </td>
              </tr>
              <tr>
                <td>汕头市</td>
                <td>南澳县</td>
              </tr>
              <tr>
                <td>茂名市</td>
                <td>高州市、信宜市</td>
              </tr>
              <tr>
                <td>肇庆市</td>
                <td>广宁县、怀集县、封开县、德庆县、高要市</td>
              </tr>
              <tr>
                <td>惠州市</td>
                <td>惠东县、龙门县</td>
              </tr>
              <tr>
                <td>梅州市</td>
                <td>
                  梅江区、梅县、大埔县★、丰顺县★、五华县★、平远县、蕉岭县、兴宁市
                </td>
              </tr>
              <tr>
                <td>汕尾市</td>
                <td>海丰县、陆河县★</td>
              </tr>
              <tr>
                <td>河源市</td>
                <td>紫金县★、龙川县★、连平县★、和平县★、东源县★</td>
              </tr>
              <tr>
                <td>阳江市</td>
                <td>阳春市</td>
              </tr>
              <tr>
                <td>清远市</td>
                <td>
                  佛冈县、阳山县★、连山壮族瑶族自治县★、连南瑶族自治县★、清新区、英德市、连州市★
                </td>
              </tr>
              <tr>
                <td>潮州市</td>
                <td>潮安县、饶平县★</td>
              </tr>
              <tr>
                <td>揭阳市</td>
                <td>揭西县★、惠来县★、普宁市★、</td>
              </tr>
              <tr>
                <td>云浮市</td>
                <td>云城区、新兴县、郁南县、云安县、罗定市</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>注：带★的为省委省政府确定的原扶贫开发重点县。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.signupInfo {
  padding: 70px 0px;
  .infoContent {
    line-height: 30px;
    font-size: 16px;
    p {
      margin: 15px;
    }
  }
  .form {
    .title {
      margin-top: 65px;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
    .table {
      table {
        width: 100%;
        border-collapse: collapse;
        tr {
          td {
            height: 37px;
            padding: 0px 10px;
            border: 1px solid #000;
            &:first-child{
              width: 180px;
            }
          }
        }
      }
    }
  }
}
</style>
